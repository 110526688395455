$delay: 0.3s;

@keyframes react-contexify__fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes react-contexify__fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(10px);
  }
}

.react-contexify__will-enter--fade {
  animation: react-contexify__fadeIn $delay ease;
}

.react-contexify__will-leave--fade {
  animation: react-contexify__fadeOut $delay ease;
}
