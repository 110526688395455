// Light Theme ------------------------------------------------------- //
.react-contexify__theme--light {
  .react-contexify__separator {
    background-color: #eee;
  }

  .react-contexify__submenu--is-open,
  .react-contexify__submenu--is-open > .react-contexify__item__content {
    color: #4393e6;
    background-color: #e0eefd;
  }

  .react-contexify__item {
    &:not(.react-contexify__item--disabled):hover
      > .react-contexify__item__content,
    &:not(.react-contexify__item--disabled):focus
      > .react-contexify__item__content {
      color: #4393e6;
      background-color: #e0eefd;
    }

    &__content {
      color: #666;
    }
  }
}
