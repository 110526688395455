$delay: 0.3s;

@keyframes react-contexify__flipInX {
  from {
    transform: perspective(800px) rotate3d(1, 0, 0, 45deg);
  }

  to {
    transform: perspective(800px);
  }
}

@keyframes react-contexify__flipOutX {
  from {
    transform: perspective(800px);
  }

  to {
    transform: perspective(800px) rotate3d(1, 0, 0, 45deg);
    opacity: 0;
  }
}

.react-contexify__will-enter--flip {
  backface-visibility: visible !important;
  transform-origin: top center;
  animation: react-contexify__flipInX $delay;
}

.react-contexify__will-leave--flip {
  transform-origin: top center;
  animation: react-contexify__flipOutX $delay;
  backface-visibility: visible !important;
}

@keyframes swing-in-top-fwd {
  0% {
    transform: rotateX(-100deg);
    transform-origin: top;
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    transform-origin: top;
    opacity: 1;
  }
}
