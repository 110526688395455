// Dark Theme ------------------------------------------------------- //
.react-contexify__theme--dark {
  background-color: rgba(40, 40, 40, 0.98);

  .react-contexify__submenu {
    background-color: rgba(40, 40, 40, 0.98);
  }
  .react-contexify__separator {
    background-color: #eee;
  }
  .react-contexify__item {
    &__content {
      color: #ffffff;
    }
  }
}
