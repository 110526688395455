.react-contexify {
  position: fixed;
  opacity: 0;
  user-select: none;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  padding: 6px 0;
  min-width: 200px;
  z-index: 100;

  &__submenu--is-open,
  &__submenu--is-open > &__item__content {
    color: white;
    background-color: #4393e6;
  }

  &__submenu--is-open > &__submenu {
    pointer-events: initial;
    opacity: 1;
  }

  & &__submenu {
    position: absolute;
    /* negate padding */
    top: -6px;
    pointer-events: none;
    transition: opacity 0.275s;
  }

  &__submenu-arrow {
    margin-left: auto;
    font-size: 12px;
  }

  &__separator {
    width: 100%;
    height: 1px;
    cursor: default;
    margin: 4px 0;
    background-color: rgba(0, 0, 0, 0.2);
  }

  &__will-leave--disabled {
    pointer-events: none;
  }

  &__item {
    cursor: pointer;
    position: relative;

    &:focus{
      outline: 0;
    }

    &:not(&--disabled):hover > &__content,
    &:not(&--disabled):focus > &__content {
      color: white;
      background-color: #4393e6;
    }

    &:not(&--disabled):hover > .react-contexify__submenu {
      pointer-events: initial;
      opacity: 1;
    }

    &--disabled {
      cursor: default;
      opacity: 0.5;
    }
    &__content {
      padding: 6px 12px;
      display: flex;
      align-items: center;
      white-space: nowrap;
      color: #333;
      position: relative;
    }
  }
}
