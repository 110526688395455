$delay: 0.3s;

@keyframes react-contexify__scaleIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 1;
  }
}

@keyframes react-contexify__scaleOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

.react-contexify__will-enter--scale {
  transform-origin: top left;
  animation: react-contexify__scaleIn $delay;
}

.react-contexify__will-leave--scale {
  transform-origin: top left;
  animation: react-contexify__scaleOut $delay;
}
