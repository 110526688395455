html {
  @apply bg-gray-900;
}

video::cue {
  font-family: sans-serif;
  font-size: 110%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}

.emoji {
  display: inline-block;
  width: auto;
  height: 1rem;
}

.timetableScrollContainer {
  contain: paint;
  max-height: calc(100vh - 162px);
}

.scrollbar-wh-4-200-600::-webkit-scrollbar-thumb {
  @apply bg-gray-600;
}

.scrollbar-wh-4-200-600::-webkit-scrollbar {
  @apply bg-gray-200;
  @apply w-2;
  @apply h-2;
}

.scrollbar-wh-2-200-600::-webkit-scrollbar-thumb {
  @apply bg-gray-600;
}

.scrollbar-wh-2-200-600::-webkit-scrollbar {
  @apply bg-gray-200;
  @apply w-1;
  @apply h-1;
}

.align-super {
  vertical-align: super;
}

.scrollbar-w-1-200-600::-webkit-scrollbar-thumb {
  @apply bg-gray-600;
}

.scrollbar-w-1-200-600::-webkit-scrollbar {
  @apply bg-gray-200;
  width: 1px;
  @apply h-0;
}

.scrollbar-h-2-200-600::-webkit-scrollbar-thumb {
  @apply bg-gray-200;
}

.scrollbar-h-2-200-600::-webkit-scrollbar {
  @apply bg-gray-600;
  @apply w-0;
  @apply h-1;
}

.scrollbar-h-1-200-600::-webkit-scrollbar-thumb {
  @apply bg-gray-600;
}

.scrollbar-h-1-200-600::-webkit-scrollbar {
  @apply bg-gray-200;
  @apply w-0;
  height: 1px;
}

.programDescription {
  a {
    @apply text-blue-600;
  }
}

.react-contexify__item--disabled {
  opacity: 1 !important;
}
